
import React, {useState} from "react"
import PropTypes from "prop-types"
import Layout from "../../layout"

import WebappsForm from "../../webapps/form"

const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps")

const ENTITYID="nnproducttag"


const productpickerfields = [
	{"label":"Last Update", "dbfield": "nnproduct_lastupdate", "type": "datetime", "filtertype": "datetime"},
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Seller SKU", "dbfield": "nnproduct_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Name", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "textbox"},
	{"label":"Active", "dbfield": "nnproduct_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Price", "dbfield": "nnproduct_price", "type": "currency", "filtertype": "currency"},
];

const productpickerfilter = [
	{"field":"nnproduct_haspage", "operation": "=", "value": 1}
];


const searchFields = [
	{"label":"Order", "dbfield": "nnproducttag_order", "type": "integer", "filtertype": "integer"},
	{"label":"Image", "dbfield": "nnproduct_image", "type": "image", "filtertype": "image"},
	{"label":"Product", "dbfield": "nnproduct_fullname", "type": "text", "filtertype": "text"},
];


const formFields = [
	[
		{"label":"Order", "field": "nnproducttag_order", "value": "", "input": "integer", "mode": "required"},
		{"label":"Product", "field": "nnproduct_fullname", "value": "", "input": "picker", "mode": "required", "pickerfields": productpickerfields, "pickerfilters": productpickerfilter},
		{"label":"Image", "field": "nnproduct_image", "value": "", "input": "image", "mode": "readonly"},
	]
];




const ListmanagerProducttagPage = ({location, allowadd, tagid, customFormFields}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");

	function customDeleteDone(entity, pkid, payloadlist, token, subtablelist, callback)
	{
		negosyonowAPI.buildEntityId(tagid,token,"tag").then(nnresponse => {
			callback();
		});
	}

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}

		params.nntag_id = tagid;
		if (params.hasOwnProperty("nnproducttag_order")) {
			webappsAPI.sortRecords(entity, token, "nnproducttag_order", pkid, params.nnproducttag_order, "nntag_id="+tagid, function(sortresponse) {
				saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback);
			});
			return;
		}
		saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback);

	}


	function saveChanges(entity, params, token, payloadlist, subformpayloadlist, callback)
	{
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntityId(tagid,token,"tag").then(nnresponse => {
					callback(response);
				});
			} else {
				callback(response);
			}
		});
	}


	return <Layout fullPath={location.pathname}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={true}
				pagetitle={pagetitle}
				mobilerowtitlefield={["nnproduct_fullname"]}
				searchFields={searchFields}
				editFields={customFormFields.length>0?customFormFields:formFields}
				newformFields={customFormFields.length>0?customFormFields:formFields}
				customSubmit={customSubmit}
				customDeleteDone={customDeleteDone}
				userSearchFilter={[
					{"field":"nntag_id", "operation":"=", "value": tagid}
				]}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


ListmanagerProducttagPage.propTypes = {
	customFormFields: PropTypes.array
}


ListmanagerProducttagPage.defaultProps = {
	customFormFields: []
}

export default ListmanagerProducttagPage;
