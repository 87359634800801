
import React from "react"
import ListmanagerProducttagPage from "../../components/negosyonow/listmanagers/producttag"

const NewitemsPage = ({location}) => {
	return <ListmanagerProducttagPage
				location={location}
				allowadd={true}
				tagid={42}
			/>
}


export default NewitemsPage;
